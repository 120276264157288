<template>
  <b-overlay :show="loading">
    <b-row class="match-height">
      <!--  left column   -->
      <b-col
        xl="5"
        sm="12"
      >
        <b-row>
          <b-col cols="12">
            <top-discounts-table />
          </b-col>
          <b-col cols="12">
            <!-- orders overview by country  -->
            <orders-overview
              :orders="orders"
              :show-force-reload="false"
            >
              <b-form-group
                label="Select discount"
                class="mr-1 mt-1"
              >
                <custom-select
                  v-model="salesChart.discount"
                  module-name="discountsList"
                  label="name"
                  placeholder="Select discount"
                  @input="onSelectDiscount"
                />
              </b-form-group>
              <b-form-group
                label="Select affiliate"
                class="mr-1 mt-1"
              >
                <custom-select
                  v-model="salesChart.affiliate"
                  module-name="partnersList"
                  label="email"
                  :reduce-func="affiliateReduceFunc"
                  placeholder="Select affiliate"
                  @input="onselectAffiliate"
                />
              </b-form-group>
            </orders-overview>
          </b-col>
        </b-row>
      </b-col>
      <!--  right column    -->
      <b-col
        xl="7"
        sm="12"
      >
        <b-row>
          <b-col cols="12">
            <top-affiliates-table />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-overlay>
</template>
<script>
import {
  BOverlay, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import TopDiscountsTable from '@/views/statistics/affiliate-statistics/components/TopDiscountsTable.vue'
import TopAffiliatesTable from '@/views/statistics/affiliate-statistics/components/TopAffiliatesTable.vue'
import StatisticsAPI from '@/api/statistics'
import CustomSelect from '@/components/ui/forms/CustomSelect.vue'
import OrdersOverview from '@/components/page/orders/OrdersOverview.vue'

export default {
  components: {
    TopAffiliatesTable,
    TopDiscountsTable,
    CustomSelect,
    OrdersOverview,
    BOverlay,
    BRow,
    BFormGroup,
    BCol,
  },
  data() {
    return {
      loading: false,
      salesChart: {
        discount: null,
        affiliate: null,
      },
      orders: [],
    }
  },
  methods: {
    onSelectDiscount(id) {
      this.salesChart.discount = id
      this.searchOrders()
    },
    onselectAffiliate(id) {
      this.salesChart.affiliate = id
      this.searchOrders()
    },
    searchOrders() {
      StatisticsAPI.getOrdersDataByAffAndDiscount(this.salesChart.discount, this.salesChart.affiliate)
        .then(response => {
          this.orders = response
        })
    },
    affiliateReduceFunc(affs) {
      return affs.map(item => ({ id: item[0].id, email: item[0].user.email }))
    },
  },
}
</script>
