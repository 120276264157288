<template>
  <b-row class="justify-content-between">
    <b-col>
      <b-form-group
        class="mb-0"
      >
        <label class="d-inline-block text-sm-left mr-50">Per page</label>
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
          class="w-50"
        />
      </b-form-group>
    </b-col>
    <b-col>
      <b-pagination
        v-model="currentPage"
        :total-rows="this.$parent.totalRows"
        :per-page="this.$parent.perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
    <b-col class="text-right">
      Total {{ this.$parent.totalRows }} rows
    </b-col>
  </b-row>
</template>

<script>
import {
  BPagination, BFormSelect, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BPagination,
    BFormSelect,
    BRow,
    BCol,
    BFormGroup,
  },
  data() {
    return {
      pageOptions: [5, 25, 50, 100],
      currentPage: this.$parent.currentPage,
      perPage: this.$parent.perPage,
    }
  },
  watch: {
    currentPage(val) {
      this.$parent.currentPage = val
    },
    perPage(val) {
      this.$parent.perPage = val
    },
  },
}
</script>
