import RefreshButton from '@/components/ui/tables/RefreshButton.vue'
import TableLoading from '@/components/ui/tables/TableLoading.vue'
import PaginationRow from '@/components/ui/tables/PaginationRow.vue'

export default {
  components: {
    RefreshButton,
    TableLoading,
    PaginationRow,
  },
  data() {
    return {
      perPage: 25,
      totalRows: 1,
      currentPage: 1,
      isLoading: false,
    }
  },
}
