<template>
  <b-card>
    <b-card-header>
      <b-card-title>Top affiliates</b-card-title>

      <b-form-group class="my-1 ml-auto mr-1">
        <h5>Search by email</h5>
        <b-input-group>
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Search by name"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <b-form-group class="mb-1 align-middle mt-3 ml-1 mr-1">
        <b-button-group>
          <b-button
            variant="outline-primary"
            @click="setDateRange(0)"
          >
            All
          </b-button>
          <b-button
            variant="outline-primary"
            @click="setDateRange(30)"
          >
            30
          </b-button>
          <b-button
            variant="outline-primary"
            @click="setDateRange(60)"
          >
            60
          </b-button>
          <b-button
            variant="outline-primary"
            @click="setDateRange(90)"
          >
            90
          </b-button>
        </b-button-group>
      </b-form-group>

      <b-form-group class="mt-1">
        <h5>Date range</h5>
        <div class="d-flex">
          <flat-pickr
            v-model="dateRange"
            class="form-control"
            :config="{ wrap: true, mode: 'range', dateFormat: 'Y-m-d'}"
          />

          <b-button
            variant="outline-primary"
            :disabled="dateRange == null"
            @click.prevent="onSearch"
          >
            <feather-icon icon="SearchIcon" />
          </b-button>
        </div>
      </b-form-group>
    </b-card-header>
    <b-card-body>
      <b-table
        :items="items"
        :fields="fields"
        striped
        responsive
        small
        :per-page="perPage"
        :current-page="currentPage"
        :busy="isLoading"
        :sort-desc="true"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #table-busy>
          <table-loading />
        </template>

        <template #cell(affiliate)="data">
          <b-link :to="{name: 'affiliate-detail', params: {id: data.item.id}}">
            {{ data.item.email }}
          </b-link>
        </template>

        <template #cell(first_orders_count)="data">
          {{ data.item.first_orders_count || 0 }}
        </template>

        <template #cell(first_orders_sum_price)="data">
          {{ formatCurrency(data.item.first_orders_sum_price, 'USD') }}
        </template>

        <template #cell(total_orders_count)="data">
          {{ data.item.total_orders_count || 0 }}
        </template>

        <template #cell(total_orders_sum_price)="data">
          {{ formatCurrency(data.item.total_orders_sum_price, 'USD') }}
        </template>

        <template #cell(registered_users_count)="data">
          {{ data.item.registered_users_count || 0 }}
        </template>

      </b-table>
      <pagination-row />
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BButton, BFormGroup, BTable,
  BInputGroup, BFormInput, BInputGroupAppend, BLink, BButtonGroup,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import table from '@/mixins/table'
import StatisticsAPI from '@/api/statistics'
import { formatFloat, formatCurrency } from '@/plugins/formaters'

export default {
  components: {
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    flatPickr,
    BLink,
    BButtonGroup,
  },
  mixins: [table],
  data() {
    return {
      discounts: null,
      fields: [
        {
          key: 'affiliate',
          sortable: true,
        },
        {
          key: 'first_orders_count',
          sortable: true,
        },
        {
          key: 'first_orders_sum_price',
          sortable: true,
        },
        {
          key: 'first_orders_sum_price',
          sortable: true,
        },
        {
          key: 'total_orders_count',
          sortable: true,
        },
        {
          key: 'total_orders_sum_price',
          sortable: true,
        },
        {
          key: 'registered_users_count',
          sortable: true,
        },
      ],
      dateRange: null,
      from: null,
      to: null,
      items: [],
      isLoading: false,
      filterOn: ['email'],
      filter: null,
      all: true,
    }
  },
  beforeMount() {
    this.onSearch()
  },
  methods: {
    formatFloat,
    formatCurrency,
    setDateRange(days) {
      if (days === 0) {
        this.from = null
        this.to = null
        this.all = true
        this.onSearch()
        return
      }

      const now = new Date()
      const back = new Date()
      back.setDate(back.getDate() - days)

      this.dateRange = `${back.toISOString().slice(0, 10)} to ${now.toISOString().slice(0, 10)}`
      this.all = false

      this.onSearch()
    },
    onSearch() {
      this.isLoading = true
      if (this.dateRange !== null && this.all === false) {
        const date = this.dateRange.split(' to ')
        this.from = date[0].concat(' 00:00:00')
        this.to = date[1].concat(' 23:59:59')
      }
      StatisticsAPI.getTopAffiliates(this.from, this.to)
        .then(response => {
          this.items = response
          this.totalRows = this.items.length
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style lang="scss">
@import "../../../../@core/scss/vue/libs/vue-flatpicker";
</style>
