<template>
  <b-overlay
    :show="this.$parent.ordersLoading"
    :variant="skin"
    spinner-variant="primary"
  >
    <b-card
      v-if="orders"
      no-body
    >
      <b-card-header class="align-items-baseline">
        <b-card-title class=" my-50">
          <h4 class="vh-center">Paid orders overview
          </h4>
          <b-button
            v-if="showForceReload"
            v-b-tooltip.hover.top="'Force reload statistics'"
            class="float-left"
            variant="outline-primary"
            @click="$emit('force-reload')"
          >
            <feather-icon icon="RepeatIcon" />
          </b-button>
          <date-range-selector

            @select="updateData"
          />
          <slot class="m-50" />
        </b-card-title>
        <p class="font-medium-2 pl-0 pb-0 p-05 font-weight-bold text-header text-right ml-auto my-50">
          Total orders: <span
            class="font-weight-bolder my-1"
            :class="`text-${skin==='light'?'darker':'white'}`"
          >{{ parseFloat(orders.map(item => parseInt(item.orders)).reduce((prev, curr) => prev + curr, 0), 0) }}</span>
          <br>
          $ <span
            class="font-weight-bolder"
            :class="`text-${skin==='light'?'darker':'white'}`"
          >{{ parseFloat(orders.map(item => parseFloat(item.profit)).reduce((prev, curr) => prev + curr, 0), 0) }}</span>
          <br>
          <span
            v-if="showHourRate"
            class="font-weight-bolder"
            :class="`text-${skin==='light'?'darker':'white'}`"
          > {{ getHourRate }} </span>
        </p>
      </b-card-header>

      <b-card-body
        v-if="!this.$parent.ordersLoading"
        class="pb-0 position-relative"
      >

        <vue-apex-charts
          ref="chart"
          height="450px"
          :options="balanceChart.chartOptions"
          :series="balanceChart.series"
        />
        <b-overlay
          :show="false"
          no-wrap
          :variant="skin"
          blur="2px"
          opacity=".95"
        >
          <template #overlay>
            <div class="text-center">
              There is not orders yet.
            </div>
          </template>
        </b-overlay>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BOverlay, VBPopover,
  VBTooltip, BButton,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { get } from 'vuex-pathify'
import {
  formatCurrency, formatDate, formatNumber,
} from '@/plugins/formaters'
import DateRangeSelector from '@/components/page/orders/DateRangeSelector.vue'

export default {
  components: {
    BButton,
    DateRangeSelector,
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BOverlay,
  },
  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  props: {
    orders: Array,
    showForceReload: {
      type: Boolean,
      default: true,
    },
    showHourRate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      warning: null,
      loaded: false,
      selection: 'all',
    }
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
    totalProfit() {
      let sum = 0
      return this.orders.map(item => {
        sum += parseFloat(item.profit)
        return sum
      })
    },
    getHourRate() {
      if (!this.orders) return ''

      const now = new Date()
      const hourUtc = now.getUTCHours()
      const lastDay = this.orders.slice(-1)
      return `${formatCurrency(Number(lastDay[0].profit) / hourUtc, 'USD')}/Hour`
    },
    balanceChart() {
      return {
        series: [
          {
            name: 'Orders',
            type: 'column',
            data: this.orders.map(item => item.orders),
          },
          {
            name: 'Daily profit',
            type: 'column',
            data: this.orders.map(item => item.profit),
          },
          {
            name: 'Total profit',
            type: 'line',
            data: this.totalProfit,
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: true,
              offsetY: -20,
            },
          },
          stroke: {
            curve: 'straight',
          },
          colors: [$themeColors.primary, $themeColors.info, $themeColors.warning],
          markers: {
            size: 2,
            colors: 'grey',
            hover: {
              size: 6,
            },
          },
          labels: this.orders.map(item => item.date),
          xaxis: {
            type: 'datetime',
            labels: {
              hideOverlappingLabels: true,
              trim: false,
              style: {
                colors: '#b9b9c3',
              },
            },
            axisBorder: {
              show: false,
            },
          },
          yaxis: [{
            labels: {
              style: {
                colors: '#b9b9c3',
              },
              formatter: val => Math.round(val),
            },
            title: {
              text: 'Orders',
            },
          }, {
            labels: {
              style: {
                colors: '#b9b9c3',
              },
              formatter: val => formatNumber(val, 'k'),
            },
            opposite: true,
            title: {
              text: 'Daily Profit',
            },
          },
          {
            labels: {
              style: {
                colors: '#b9b9c3',
              },
              formatter: val => formatNumber(val, 'm'),
            },
            opposite: true,
            title: {
              text: 'Total Profit',
            },
          },
          ],
          tooltip: {
            x: { show: true },
            y: { show: true },
          },
        },
      }
    },
  },
  methods: {
    formatDate,
    updateData(timeline) {
      const today = new Date()
      this.selection = timeline
      switch (timeline) {
        case 'one_month':
          this.$refs.chart.zoomX(
            new Date(today.getFullYear(), today.getMonth() - 1, 1).getTime(),
            today.getTime(),
          )
          break
        case 'one_year':
          this.$refs.chart.zoomX(
            new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()).getTime(),
            today.getTime(),
          )
          break
        case 'week':
          this.$refs.chart.zoomX(
            new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay()).getTime(),
            today.getTime(),
          )
          break
        case 'all':
          this.$refs.chart.zoomX(
            this.orders[0].date,
            today.getTime(),
          )
          break
        default:
      }
    },
  },
}
</script>
