<template>
  <b-button
    variant="outline-primary"
    @click="onClick"
  >
    <feather-icon
      icon="RefreshCwIcon"
      class="mr-1"
      size="15"
    />
    Refresh data
  </b-button>
</template>

<script>

import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  methods: {
    onClick() {
      this.$emit('refresh')
    },
  },
}
</script>
