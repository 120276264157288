<template>
  <v-select
    :value="defaultItem"
    :options="status === 'initial' ? [] : items"
    :label="label"
    :placeholder="status === 'success' ? placeholder : 'Loading...'"
    :reduce="notReduceById ? item => item : item => item.id"
    class="form-control-md"
    @search="onChange"
    @input="onInput"
  />
</template>

<script>
import vSelect from 'vue-select'
import { get } from 'vuex-pathify'
import { debounce } from 'lodash'
import store from '@/store'

export default {
  components: {
    vSelect,
  },
  props: {
    selectedItem: {
      type: Object,
      required: false,
      default: null,
    },
    moduleName: {
      type: String,
      required: true,
    },
    notReduceById: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    filters: {
      type: Object,
      default: () => ({
        search: '',
      }),
      required: false,
    },
    sortBy: {
      type: String,
      default: 'id',
      required: false,
    },
    sortDesc: {
      type: Boolean,
      default: false,
      required: false,
    },
    placeholder: {
      type: String,
      default: '',
      required: false,
    },
    reduceFunc: {
      type: [Function],
      default: null,
      required: false,
    },
  },
  data() {
    return {
      defaultItem: this.selectedItem !== null ? { ...this.selectedItem } : { id: null, [this.label]: null },
    }
  },
  computed: {
    items() {
      if (typeof this.reduceFunc === 'function') {
        return this.reduceFunc(this.data)
      }
      return this.data
    },
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      // eslint-disable-next-line func-names
      handler: debounce(function () {
        this.onFilterChange()
      }, 500),
    },
  },
  beforeCreate() {
    const { moduleName } = this.$options.propsData
    if (!this.$options.computed) {
      this.$options.computed = {}
    }
    Object.assign(this.$options.computed, {
      ...get(moduleName, ['data', 'status']),
    })
  },
  mounted() {
    store.dispatch(`${this.moduleName}/setSort`, { column: this.sortBy, dir: this.sortDesc ? 'DESC' : 'ASC' })
    store.dispatch(`${this.moduleName}/loadAll`)
  },
  methods: {
    onInput(value) {
      if (this.notReduceById) this.defaultItem = value
      else this.defaultItem = value !== null ? this.items.find(item => item.id === value)[this.label] : null
      this.$emit('input', value)
    },
    onChange(search) {
      this.filters.search = search
      debounce(function () {
        this.onFilterChange()
      }, 500)
    },
    onFilterChange() {
      store.dispatch(`${this.moduleName}/changeFilters`, this.filters)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
