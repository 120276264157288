<template>
  <b-button-toolbar
    class="vh-center"
  >
    <b-button-group>
      <b-button
        id="one_year"
        :class="{active: selection==='one_year'}"
        @click="$emit('select', 'one_year')"
      >
        1Y
      </b-button>
      <b-button
        id="one_month"
        :class="{active: selection==='one_month'}"
        @click="$emit('select', 'one_month')"
      >
        1M
      </b-button>
      <b-button
        id="ytd"
        :class="{active: selection==='week'}"
        @click="$emit('select', 'week')"
      >
        1W
      </b-button>

      <b-button
        id="all"
        :class="{active: selection==='all'}"
        @click="$emit('select', 'all')"
      >
        ALL
      </b-button>
    </b-button-group>
  </b-button-toolbar>
</template>
<script>
import {
  BButton, BButtonGroup, BButtonToolbar, VBPopover,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BButtonGroup,
    BButtonToolbar,
  },
  directives: {
    'b-popover': VBPopover,
  },
  data() {
    return {
      selection: 'all',
    }
  },
}
</script>
