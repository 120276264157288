<template>
  <div class="text-center text-primary my-2">
    <b-spinner
      class="align-middle mr-1"
    />
    <strong>Loading...</strong>
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
  },
}
</script>
